import styled, { css } from 'styled-components';
import { Label } from '@naf/label';
import { Text } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { spacing, icons, breakpoints, radius } from '@naf/theme';
import { ExpandableMembershipBoxVariant } from './Types';

export const Wrapper = styled.div<{ $variant: ExpandableMembershipBoxVariant }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: ${radius.s};
  background-color: ${({ $variant }) => {
    switch ($variant) {
      case ExpandableMembershipBoxVariant.Dew:
        return nafColor.primary.dew;
      case ExpandableMembershipBoxVariant.Outline:
        return nafColor.signature.white;
      default:
        return nafColor.primary.dew;
    }
  }};
  border: ${({ $variant }) => {
    switch ($variant) {
      case ExpandableMembershipBoxVariant.Dew:
        return `none`;
      case ExpandableMembershipBoxVariant.Outline:
        return `2px solid ${nafColor.primary.park}`;
      default:
        return `none`;
    }
  }};
  width: 100%;
`;

export const HeaderContent = styled.div<{ $showPriceInline?: boolean; $openByDefaultOnDesktop?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${spacing.space40} 0 ${spacing.space40} ${spacing.space24};
  padding-right: ${spacing.space24};

  ${({ $showPriceInline }) =>
    $showPriceInline &&
    css`
      flex-direction: row;
      align-items: center;

      @media (max-width: ${breakpoints.s}) {
        flex-direction: column;
        align-items: flex-start;
      }
    `}

  ${({ $openByDefaultOnDesktop }) =>
    $openByDefaultOnDesktop &&
    css`
      cursor: pointer;
    `}

  @media (max-width: ${breakpoints.s}) {
    margin-top: ${spacing.space40};
    margin-bottom: ${spacing.space40};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -${spacing.space24};
`;

export const Chevron = styled.div<{ $isOpen: boolean; $isOpenByDefault?: boolean }>`
  position: absolute;
  top: 10px;
  right: ${spacing.space16};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${spacing.space8};
  cursor: pointer;

  ${({ $isOpenByDefault }) =>
    $isOpenByDefault &&
    css`
      @media (min-width: ${breakpoints.m}) {
        display: none;
      }
    `}

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
      transition-duration: 0.3s;
    `}
    
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: rotate(0);
      transition-duration: 0.3s;
    `}

      svg {
    font-size: ${icons.m};
  }
`;

export const StyledLabel = styled(Label)`
  position: absolute;
  top: -12px;
  left: ${spacing.space16};
`;

export const StyledHeader = styled(Text)<{ $showPriceInline?: boolean }>`
  margin: 0 ${spacing.space24} 0 0;

  ${({ $showPriceInline }) =>
    $showPriceInline &&
    css`
      font-weight: 400;
      margin: 0;
    `}
`;

export const ClosedPriceWrapper = styled.div<{ $showPriceInline?: boolean }>`
  margin-top: ${spacing.space12};

  ${({ $showPriceInline }) =>
    $showPriceInline &&
    css`
      margin-top: 0;

      @media (max-width: ${breakpoints.s}) {
        margin-top: ${spacing.space12};
      }
    `}
`;

export const Price = styled(Text)`
  margin: 0;
  font-weight: 900;
  white-space: nowrap;
`;

export const PriceWrapper = styled.div`
  display: flex;
  padding: ${spacing.space24};
`;

export const ButtonWrapper = styled.div`
  margin: 0 0 ${spacing.space24} ${spacing.space24};
`;

export const TermsWrapper = styled.div`
  padding: 0 ${spacing.space24} ${spacing.space24} ${spacing.space24};
`;

export const Terms = styled(Text)`
  margin-bottom: -${spacing.space8};
  font-weight: 500;
  font-size: 1.25rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
`;

export const LoadingPrice = styled(Text)`
  margin: 0;
  font-weight: 700;
  margin-left: ${spacing.space8};
`;
