import { SanityBlock } from '@dtp/membership-service-types/dist/sanity/sanity';
import { PortableTextBlock } from '@portabletext/react';
import { LinkType } from '../../../../types/LinkType';

// Compare component.
export enum ExpandableMembershipBoxVariant {
  Dew = 'Dugg',
  Outline = 'Outline',
}

export interface ExpandableMembershipBoxProps {
  openByDefaultOnDesktop?: boolean;
  showPriceInline?: boolean;
  tag?: boolean;
  title?: string;
  variant?: ExpandableMembershipBoxVariant;
  productId?: string;
  terms?: PortableTextBlock[];
  householdMembers?: boolean;
  benefitAccordions?: MembershipBoxAccordionProps[];
  cta?: LinkType;
}

// Accordion component
export interface MembershipBoxAccordionProps {
  title?: string;
  variant?: MembershipAccordionVariant;
  content?: SanityBlock[];
  showIcon?: boolean;
}

export enum MembershipAccordionVariant {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

// Price listing accordion.
export interface Price {
  title?: string; // E.g., "1 Person", "2 Personer"
  price?: string;
}

export interface HouseholdMembershipPriceAccordionProps {
  title?: string;
  prices?: Price[];
  terms?: PortableTextBlock[];
}
