import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown';
import { TextVariant } from '@naf/text';
import { Loader } from '@naf/loader';
import { actions as getProductsAction } from '../../redux/modules/products';
import {
  MembershipBoxAccordion,
  HouseholdMembershipPriceAccordion,
} from './MembershipBoxAccordion/MembershipBoxAccordion';
import { ExpandableMembershipBoxProps, ExpandableMembershipBoxVariant } from './Types';
import useSelector from '../../redux/typedHooks';
import BlockContent from '../block-content/BlockContent';
import { CustomerProducts, mergeProductsWithAddOns } from './productUtils';
import * as S from './Styles';
import { useMediaQuery } from './utils';
import CTAButton from './CtaButton/CtaButton';

export const ExpandableMembershipBox = ({
  tag,
  openByDefaultOnDesktop = false,
  showPriceInline = false,
  title,
  variant = ExpandableMembershipBoxVariant.Dew,
  productId,
  terms,
  householdMembers,
  benefitAccordions = [],
  cta,
}: ExpandableMembershipBoxProps) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const [open, setOpen] = useState(false);
  const [productPrice, setProductPrice] = useState('');
  const productState = useSelector((state) => state.products.data);
  const [products, setProducts] = useState([]);
  const baseUrl = useSelector((state) => state.application.baseUrl);

  useEffect(() => {
    if (!isMobile && openByDefaultOnDesktop) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isMobile, openByDefaultOnDesktop]);

  useEffect(() => {
    if (productState && productState.products) {
      const eligibleProducts = Object.values(productState.products);
      const customerProducts: CustomerProducts = { eligibleProducts };

      // Merge main and add-on products
      const mergedProducts = mergeProductsWithAddOns(customerProducts);
      setProducts(mergedProducts);
    }
  }, [productState]);

  useEffect(() => {
    if (productId) {
      dispatch(getProductsAction.getProducts.request(productId));
    }
  }, [dispatch, productId]);

  const selectedProduct = useMemo(() => products.find((p) => p.productNumber === productId), [products, productId]);

  useEffect(() => {
    if (selectedProduct?.productAppPrice?.['1']) {
      const price = selectedProduct.productAppPrice['1'].itemTotalPrice.toString();
      setProductPrice(price);
    } else {
      setProductPrice('');
    }
  }, [selectedProduct]);

  const householdPrices =
    householdMembers && productId
      ? Object.values(
          (products.find((p) => p.productNumber === productId)?.productAppPrice || {}) as Record<
            string,
            { itemCount: number; itemTotalPrice: number }
          >,
        ).map((priceItem) => ({
          title: `${priceItem.itemCount} ${priceItem.itemCount === 1 ? 'person' : 'personer'}`,
          price: priceItem.itemTotalPrice.toString(),
        }))
      : [];

  const handleOpen = () => setOpen((prevOpen) => !prevOpen);

  return (
    <S.Wrapper $variant={variant}>
      {tag && <S.StyledLabel text="Mest populær" variant="spruce" />}
      <S.HeaderContent
        onClick={!openByDefaultOnDesktop ? handleOpen : undefined}
        $showPriceInline={showPriceInline}
        $openByDefaultOnDesktop={!openByDefaultOnDesktop}
      >
        {title && (
          <S.StyledHeader variant={TextVariant.Header3} $showPriceInline={showPriceInline}>
            {title}
          </S.StyledHeader>
        )}
        {!open && (
          <S.ClosedPriceWrapper $showPriceInline={showPriceInline}>
            {productPrice ? (
              <S.Price variant={TextVariant.Header3}>
                {productPrice} kr
                {!showPriceInline && ' for 12 måneder'}
              </S.Price>
            ) : (
              <S.LoaderWrapper>
                <Loader size="small" />
                <S.LoadingPrice variant={TextVariant.Header3}>Laster pris..</S.LoadingPrice>
              </S.LoaderWrapper>
            )}
          </S.ClosedPriceWrapper>
        )}
      </S.HeaderContent>
      {open && (
        <S.Content>
          {benefitAccordions?.map((item) => (
            <MembershipBoxAccordion
              key={item?.title}
              variant={item?.variant}
              title={item?.title}
              content={item?.content}
            />
          ))}
          <S.PriceWrapper>
            {productPrice ? (
              <S.Price variant={TextVariant.Header3}>{productPrice} kr for 12 måneder</S.Price>
            ) : (
              <S.LoaderWrapper>
                <Loader size="small" />
                <S.LoadingPrice variant={TextVariant.Header3}>Laster pris..</S.LoadingPrice>
              </S.LoaderWrapper>
            )}
          </S.PriceWrapper>
          {householdMembers && terms && (
            <HouseholdMembershipPriceAccordion
              title="Medlemskap for flere personer"
              prices={householdPrices}
              terms={terms}
            />
          )}
          {cta && (
            <S.ButtonWrapper>
              <CTAButton cta={cta} baseUrl={baseUrl} />
            </S.ButtonWrapper>
          )}
          {!householdMembers && terms && (
            <S.TermsWrapper>
              <S.Terms variant={TextVariant.Header3}>Betingelser</S.Terms>
              <BlockContent value={terms} />
            </S.TermsWrapper>
          )}
        </S.Content>
      )}
      <S.Chevron onClick={handleOpen} $isOpen={open} $isOpenByDefault={openByDefaultOnDesktop}>
        <KeyboardArrowDown size={24} />
      </S.Chevron>
    </S.Wrapper>
  );
};
