interface Product {
  productNumber: string;
  productName: string;
  productAppPrice?: Record<string, { itemCount: number; itemTotalPrice: number }>;
}

interface AddOnProduct {
  products: Product[];
}

interface EligibleProduct {
  productNumber: string;
  productName: string;
  productAppPrice?: Record<string, { itemCount: number; itemTotalPrice: number }>;
  availableAddOnProducts?: AddOnProduct | null;
}

export interface CustomerProducts {
  eligibleProducts: EligibleProduct[];
}

/**
 * Merges main products and their associated add-on products into a single flat list.
 * We do this so that we can use addon products in the same context as main products to show price in the component.
 */

export const mergeProductsWithAddOns = (customerProducts: CustomerProducts): Product[] => {
  const allProducts: Product[] = [];

  customerProducts.eligibleProducts.forEach((product) => {
    // Add the main product
    allProducts.push({
      productNumber: product.productNumber,
      productName: product.productName,
      productAppPrice: product.productAppPrice,
    });

    // Add add-on products
    if (product.availableAddOnProducts?.products) {
      product.availableAddOnProducts.products.forEach((addOn) => {
        allProducts.push({
          productNumber: addOn.productNumber,
          productName: addOn.productName,
          productAppPrice: addOn.productAppPrice,
        });
      });
    }
  });

  return allProducts;
};
