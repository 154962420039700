import React from 'react';
import styled, { css } from 'styled-components';
import { Text } from '@naf/text';
import { nafColor } from '@nafcore/theme';
import { spacing, icons } from '@naf/theme';
import { MembershipAccordionVariant } from '../Types';

export const AccordionWrapper = styled.div<{ $isOpen: boolean; $paddingBottom?: boolean }>`
  background-color: ${({ $isOpen }) => ($isOpen ? nafColor.primary.moss : 'transparent')};
  margin-bottom: ${({ $paddingBottom }) => ($paddingBottom ? spacing.space24 : 0)};
`;

export const AccordionHeader = styled.div<{ $isOpen: boolean; $paddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: ${spacing.space24};
  align-items: flex-start;
`;

export const AccordionTitle = styled.div<{ $accordionVariant: MembershipAccordionVariant; $showIcon?: boolean }>`
  margin: ${spacing.space8} 0 ${spacing.space8} ${({ $showIcon }) => ($showIcon ? spacing.space12 : 0)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ $accordionVariant }) => {
    switch ($accordionVariant) {
      case MembershipAccordionVariant.Available:
        return nafColor.signature.black;
      case MembershipAccordionVariant.Unavailable:
        return nafColor.neutral.neutral3;
      default:
        return nafColor.neutral.neutral1;
    }
  }};
`;

export const Chevron = styled.span<{ $isOpen: boolean }>`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${spacing.space16};
  margin-bottom: ${spacing.space8};
  color: ${nafColor.signature.black} !important;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
      transition-duration: 0.3s;
    `}
  ${({ $isOpen }) =>
    !$isOpen &&
    css`
      transform: rotate(0);
      transition-duration: 0.3s;
    `}
  
  svg {
    font-size: ${icons.m};
  }
`;

export const AccordionContent = styled.div<{ $showIcon?: boolean }>`
  background-color: ${nafColor.primary.moss};
  margin: 0 ${spacing.space24} ${spacing.space32} ${spacing.space24};

  p,
  ul,
  li {
    font-size: 1rem;
    font-weight: 500;
  }
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  position: relative;
  width: ${spacing.space16};
  height: ${spacing.space16};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  box-sizing: border-box;
  margin-top: 14px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const CheckIconWrapper = styled(IconWrapper)`
  background: ${nafColor.primary.kart};

  path {
    fill: ${nafColor.signature.black};
  }
`;

export const MinusIconWrapper = styled(IconWrapper)`
  border: 2px solid ${nafColor.neutral.neutral3};

  path {
    fill: ${nafColor.neutral.neutral3};
  }
`;

export const CheckIcon = () => (
  <CheckIconWrapper>
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.18305 0.307678L9.30679 1.61085L4.07014 7.69229L0.691406 3.78279L1.81515 2.47962L4.07014 5.08596L8.18305 0.307678Z"
        fill="#062E2A"
      />
    </svg>
  </CheckIconWrapper>
);

export const MinusIcon = () => (
  <MinusIconWrapper>
    <svg width="10" height="3" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0.5" width="10" height="1" fill="#C1C1C1" />
    </svg>
  </MinusIconWrapper>
);

export const HouseholdTitle = styled(Text)`
  margin: ${spacing.space8} 0 ${spacing.space8} 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.25rem;
  width: 100%;
`;

export const HouseholdContent = styled.div`
  background-color: ${nafColor.primary.moss};
  padding: ${spacing.space8};
  margin-left: ${spacing.space24};
  margin-bottom: ${spacing.space8};
`;

export const PriceList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${spacing.space16};
  background-color: ${nafColor.primary.moss};
  margin-top: ${spacing.space8};
`;

export const PriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing.space4} 0;
`;

export const Person = styled.div`
  flex: 1;
  font-weight: 500;
  font-size: 1rem;
`;

export const Price = styled.div`
  flex: 1;
  text-align: right;
  margin-right: ${spacing.space32};
`;

export const TermsTitle = styled(Text)`
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: -${spacing.space8};
`;

export const TermsContent = styled.div`
  margin-right: ${spacing.space24};
`;

export const Terms = styled.div``;
