import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { ButtonLink } from '@naf/button-link';
import { CompareMembershipBlockType } from '../../../../../types/CompareMembershipBlockType';
import { ExpandableMembershipBox } from '../../MembershipBox/ExpandableMembershipBox';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { LinkType } from '../../../../../types/LinkType';

export const CompareMembershipBlock = ({ block }: { block: CompareMembershipBlockType }) => {
  if (!block) return null;

  return (
    <CompareBlockWrapper>
      {block?.blockTitle && (
        <CompareBlockTitle>
          <CompareTitle variant={TextVariant.Header1}>{block?.blockTitle}</CompareTitle>
          {block?.cta && (
            <DesktopLinkWrapper>
              <LinkField link={block?.cta} />
            </DesktopLinkWrapper>
          )}
        </CompareBlockTitle>
      )}
      <CompareBlocks>
        {block?.memberships?.length > 0
          ? block?.memberships?.map((membership) => (
              <ExpandableMembershipBox
                key={membership?.title}
                tag={membership?.tag}
                title={membership?.title}
                variant={membership?.variant}
                openByDefaultOnDesktop={membership?.openByDefaultOnDesktop}
                showPriceInline={membership?.showPriceInline}
                productId={membership?.productId}
                terms={membership?.terms}
                householdMembers={membership?.householdMembers}
                benefitAccordions={membership?.benefitAccordions}
                cta={membership?.cta || null}
              />
            ))
          : null}
      </CompareBlocks>
      {block?.cta && (
        <MobileLinkWrapper>
          <LinkField link={block?.cta} />
        </MobileLinkWrapper>
      )}
    </CompareBlockWrapper>
  );
};

const LinkField = ({ link }: { link: LinkType }) => {
  const target = useDocumentUrlWithNode(link?.internalReference);

  switch (link?.targetType) {
    case 'internalReference':
      if (link?.internalReference) {
        return <ButtonLink as={Link} to={target} text={link?.title} />;
      }
      return null;

    case 'externalTarget':
      if (link?.externalTarget?.href) {
        return <ButtonLink href={link?.externalTarget?.href} blank={link?.externalTarget?.blank} text={link?.title} />;
      }
      return null;

    default:
      return null;
  }
};

const CompareBlockWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  margin-bottom: ${spacing.space24};
`;

const CompareBlockTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompareTitle = styled(Text)`
  margin: ${spacing.space16} 0 ${spacing.space32} 0;

  ${CompareBlockWrapper}:nth-of-type(n + 2) & {
    font-size: 1.75rem;
  }
`;

const DesktopLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 100px;

  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const MobileLinkWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.m}) {
    display: flex;
    margin-top: ${spacing.space24};
    min-width: 100px;
  }
`;

const CompareBlocks = styled.div`
  display: flex;
  gap: ${spacing.space24};
  width: 100%;
  align-items: flex-start;

  @media (max-width: ${breakpoints.m}) {
    flex-direction: column;
  }
`;
