import React, { useState } from 'react';
import { KeyboardArrowDown } from '@styled-icons/material/KeyboardArrowDown';
import { CheckIcon, MinusIcon } from './Styles';
import {
  HouseholdMembershipPriceAccordionProps,
  MembershipAccordionVariant,
  MembershipBoxAccordionProps,
} from '../Types';
import * as S from './Styles';
import BlockContent from '../../block-content/BlockContent';

export const MembershipBoxAccordion: React.FC<MembershipBoxAccordionProps> = ({
  title,
  content,
  showIcon = true,
  variant = MembershipAccordionVariant.Available,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <S.AccordionWrapper $isOpen={isOpen}>
      <S.AccordionHeader onClick={toggleAccordion} $isOpen={isOpen}>
        {showIcon && (variant === MembershipAccordionVariant.Available ? <CheckIcon /> : <MinusIcon />)}
        <S.AccordionTitle $accordionVariant={variant} $showIcon={showIcon}>
          {title}
          <S.Chevron $isOpen={isOpen}>
            <KeyboardArrowDown size={24} />
          </S.Chevron>
        </S.AccordionTitle>
      </S.AccordionHeader>
      {isOpen && (
        <S.AccordionContent $showIcon={showIcon}>
          <BlockContent value={content} />
        </S.AccordionContent>
      )}
    </S.AccordionWrapper>
  );
};

export const HouseholdMembershipPriceAccordion: React.FC<HouseholdMembershipPriceAccordionProps> = ({
  title,
  prices,
  terms,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => setIsOpen(!isOpen);

  return (
    <S.AccordionWrapper $isOpen={isOpen} $paddingBottom>
      <S.AccordionHeader onClick={toggleAccordion} $isOpen={isOpen} $paddingBottom>
        <S.HouseholdTitle>
          {title}
          <S.Chevron $isOpen={isOpen}>
            <KeyboardArrowDown size={24} />
          </S.Chevron>
        </S.HouseholdTitle>
      </S.AccordionHeader>
      {isOpen && (
        <S.HouseholdContent>
          <S.PriceList>
            {prices?.map((price) => (
              <S.PriceItem key={price?.title}>
                <S.Person>{price?.title}</S.Person>
                <S.Price>{price?.price} kr</S.Price>
              </S.PriceItem>
            ))}
          </S.PriceList>
          {terms && (
            <S.TermsContent>
              <S.TermsTitle>Betingelser</S.TermsTitle>
              <S.Terms>
                <BlockContent value={terms} />
              </S.Terms>
            </S.TermsContent>
          )}
        </S.HouseholdContent>
      )}
    </S.AccordionWrapper>
  );
};
